import React from "react";

export const notificationAlert = React.createRef();

export const atrament = React.createRef();

export const videoModalRef = React.createRef();

export const localStorageKey = {
  STROKES: "STROKES",
  MODAL_STROKES: "MODAL_STROKES",
  NOTE_BOX: "NOTE_BOX",
  MODAL_NOTE_BOX: "MODAL_NOTE_BOX",
  TEXT_TOOL: "TEXT_TOOL",
  MODAL_TEXT_TOOL: "MODAL_TEXT_TOOL",
};

export const Colors = {
  green: "#25AC52",
  yellow: "#f1c40f",
  red: "red",
  purple: "#6474FC",
  dimGray: "#666666",
  greyChateau: "#A5A8AB",
  dodgerBlue: "#057AFF",
  lightAzure: "#5AA9FF",
  darkChambray: "#365992",
  darkTangerine: "#FFAB1A",
};
export const books = [
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF1-S-cover.jpg",
    href: "https://faf1.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF1-W-cover.jpg",
    href: "https://faf1-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF2-S-cover.jpg",
    href: "https://faf2.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF2-W-cover.jpg",
    href: "https://faf2-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF3-S-cover.jpg",
    href: "https://faf3.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF3-W-cover.jpg",
    href: "https://faf3-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF4-S-cover.jpg",
    href: "https://faf4.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF4-W-cover.jpg",
    href: "https://faf4-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF5-S-cover.jpg",
    href: "https://faf5.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF5-W-cover.jpg",
    href: "https://faf5-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FP6-S-cover.jpg",
    href: "https://fp6.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FP6-W-cover.jpg",
    href: "https://fp6-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FP7-S-cover.png",
    href: "https://fp7-sb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FP7-W-cover.jpg",
    href: "https://fp7-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FG10-S-cover.png",
    href: "https://fg10-sb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FG10-W-cover.jpg",
    href: "https://fg10-wb.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF3-S-V2-cover.jpg",
    href: "https://faf3-sb-v2.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF3-W-V2-cover.jpg",
    href: "https://faf3-wb-v2.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF4-S-V2-cover.jpg",
    href: "https://faf4-sb-v2.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF4-W-V2-cover.jpg",
    href: "https://faf4-wb-v2.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF5-S-V2-cover.jpg",
    href: "https://faf5-sb-v2.sachso.edu.vn",
  },
  {
    image: "https://cdn.sachso.edu.vn/mn1/img/FAF5-W-V2-cover.jpg",
    href: "https://faf5-wb-v2.sachso.edu.vn",
  },
];

export const pageView = {
  ONE_PAGE: "one_page",
  TWO_PAGE: "two_page",
  SCROLL_PAGE: "scroll_page",
};

export const dragItemTypes = {
  DRAG: "DRAG",
};
