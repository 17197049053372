import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import TypeInNoSubmit from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P13-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/audio.e1.mp3",
    video: "",
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E1/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E1/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/tieude.e1.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E1/4.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/4.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P13-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/sing.e2.mp3",
    video: "",
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/tieude.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/Audio/4.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P13-E1",
    audio: "",
    video: "",
    component: D1,
    typeInput: "center",
    fontSize: 30,
    inputHeight: "100%",
    //isAllowSubmit: false,
    isLargeInput: true,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/Key/answerKey.png",
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/1.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/3.jpg",
          input: true,
          answer: "2",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/5.jpg",
          input: true,
          answer: "1",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/7.jpg",
          input: true,
          answer: "4",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/9.jpg",
          input: true,
          answer: "3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/11.jpg",
          input: true,
          answer: "2",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/12.jpg",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E3/13.jpg",
        },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P13-E1",
    audio: "",
    video: "",
    component: TypeInNoSubmit,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page13/E4/1.jpg",
        },
      ],
    ],
  },
};

export default json;
