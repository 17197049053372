import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN1-U6-P56-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 14.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn1/Videos/Unit 6_Track 14_Apples, apples, 1, 2, 3!.mp4",
    component: UI,
    recorder: true,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page56/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page56/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page56/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/Track 14.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page56/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page56/E1/5.jpg",
        },
      ],
    ],
  },
};

export default json;
