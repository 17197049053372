import { Form, Input } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React from "react";

export function toFormValues(array) {
  const list = array.map((item, index) => [index, item]);
  return Object.fromEntries(list); // {0: 'a', 1: 'b', 2: 'c'}
}

export function calculateResultWrite(
  userAnswers,
  answers,
  checkUppercase,
  character,
  checkDuplicated
) {
  let WriteUserAnswers = [];
  let B = userAnswers.some((e) => e !== "" && e !== undefined);

  if (B) {
    WriteUserAnswers = userAnswers.map((i) => (i !== undefined ? i : ""));
  } else {
    WriteUserAnswers = answers.map((i) => "wwww");
  }

  const listStudentAnswer = WriteUserAnswers.map((str) => str.split(character));

  const listAnswers = answers.map((item) => item.split(character));
  //
  const notSpacesAnswer = listAnswers.map((i) =>
    i.map((a) =>
      a
        .trim()
        .replace(/\s|[\.\?]$/g, "")
        .toLowerCase()
    )
  );
  const notSpaces = listStudentAnswer.map((item) =>
    item.map((a) =>
      a
        .trim()
        .replace(/\s|[\.\?]$/g, "")
        .toLowerCase()
    )
  );
  let resultWrite = [];

  resultWrite = notSpaces.map((cuacua, cococ) => {
    const vailua = cuacua.some((e) => !notSpacesAnswer[cococ].includes(e));
    return vailua !== true ? WriteUserAnswers[cococ] : "";
  });

  let AnswerNotDuplicate = [];
  let mama = [];

  let cheche;

  for (let i = 0; i < notSpacesAnswer.length; i++) {
    const cheo = resultWrite[i]
      .toLowerCase()
      .replace(/\s|\.$/g, "")
      .split(",");
    cheo.forEach((e) => (cheche = mama.flat().includes(e)));
    if (cheche) {
      mama.push([""]);
    } else {
      mama.push(cheo);
    }
    const value = resultWrite[i].toLowerCase();
    if (cheche) {
      AnswerNotDuplicate.push("");
    } else {
      AnswerNotDuplicate.push(value);
    }
  }
  userAnswers = userAnswers.map((item) => item ?? ""); // undefined => ''
  const res = listAnswers.map((item, index) => {
    const ThHaha = notSpaces[index].some(
      (ye) => !notSpacesAnswer[index].includes(ye.replace(/\s|\.$/g, ""))
    );

    const isCorrect = item.some(
      (x) =>
        checkUppercase
          ? x.trim() === userAnswers[index].trim() // chữ hoa khác chữ thường
          : !ThHaha // ko phân biệt hoa thường, bỏ dấu cuối câu
    );
    if (checkDuplicated) {
      const Check = resultWrite.map((e) => e.toLowerCase());
      const isDupicated = AnswerNotDuplicate[index] === "";
      if (WriteUserAnswers[index] !== "" && isDupicated) return [index, false];
    }
    return [index, isCorrect];
  });
  const listBoolean = Object.fromEntries(res); //biến hiển thị css đúng/sai
  const booleanArray = Object.values(listBoolean).filter((x) => x !== null);
  // params
  const correct = booleanArray.reduce(
    (total, item) => (total += item ? 1 : 0),
    0
  ); // phát hiện câu đúng cộng 1 điểm
  const total = answers.filter((x) => x !== "").length; // tổng số đáp án khác ''
  const percent = parseInt((correct * 100) / total); // tính %
  const resultString = `${correct}/${total}`; // điểm / tổng
  const star = percent / 20;
  //
  return { listBoolean, booleanArray, percent, resultString, star };
}

function Write({ handleChange, isHello, id, data, elementStyle }) {
  const initialInputStyle = { color: "black", ...data.inputStyle };
  let inputStyle = initialInputStyle;
  //set Style
  if (data.isDoing === false) {
    const isCorrect = data.resultWrite.listBoolean[id];
    if (isCorrect !== null) {
      const color = isCorrect ? "green" : "red";
      inputStyle.color = isHello === true ? "green" : color;
    }
  }
  // show icon check
  const showIcon = () => {
    if (data.isDoing || data.isHiddenCheck) return null;
    const isCorrect = data.resultWrite?.listBoolean[id];
    if (isHello !== true) {
      if (isCorrect === true)
        return (
          <CheckCircleOutlined style={{ fontSize: 25, color: "#2ecc71" }} />
        );
      if (isCorrect === false)
        return (
          <CloseCircleOutlined style={{ fontSize: 25, color: "#e74c3c" }} />
        );
    } else {
      return <CheckCircleOutlined style={{ fontSize: 25, color: "#2ecc71" }} />;
    }
  };

  return (
    <div style={{ display: "inline-flex", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          height: 33,
          // borderBottom: inputStyle.borderBottom ?? "solid gray 2px",
          width: "100%",
          ...data.underlineStyle,
        }}
      />
      <Form.Item
        name={`${id}`}
        style={{ marginBottom: 0 }}
        // rules={[{ required: true, message: 'Please fill the answer' },]}
      >
        <Input
          bordered={false}
          maxLength={inputStyle.maxLength}
          onChange={handleChange}
          style={{
            fontSize: 24,
            // height: 36,
            // padding: 5,
            borderBottom: "solid gray 2px",
            ...inputStyle,
            ...elementStyle,
          }}
        />
      </Form.Item>
      {showIcon()}
    </div>
  );
}

export default Write;
