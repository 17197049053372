import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN1-U6-P61-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    hideBtnFooter: true,
    totalInput: 1,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page61/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page61/E1/2.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page61/E1/3.jpg",
        },

        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page61/E1/4.jpg",
          audioUrl: "",
        },

        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page61/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page61/E1/6.jpg",
          audioUrl: "",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page34/E1/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/10.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/14.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/16.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/18.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/20.jpg",
        },
      ],
    ],
  },
};

export default json;
