import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "LQTAMN1-S-P5-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/P5/Track 1.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/b.jpg",
      //   ,
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/c.jpg" },
      // ],

      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/How are you.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/4.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/5.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/6.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/How are you.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/7.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/im fine.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/9.jpg" },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/How are you.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/13.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/im fine.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/15.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/im fine.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/16.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/18.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P5/im fine.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/20.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P5-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/b.jpg",
      //   ,
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/a.jpg" }],

      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E2/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E2/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E2/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E2/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E2/5.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P5-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/b.jpg",
      //   ,
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E1/a.jpg" }],

      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E3/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E3/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E3/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E3/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page5/E3/5.jpg" }],
    ],
  },
};

export default json;
