import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P15-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/P15/Track 4.mp3",
    // video: "https://cdn.sachso.edu.vn/mn1/Videos/Unit 4_Track 9_What's in the box.mp4",
    component: UI,
    recorder: true,
    WidthContent: "80%",
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P15/Track 4.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E1/5.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN1-U1-P15-E2",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/P15/Track 4.mp3",
    // video: "https://cdn.sachso.edu.vn/mn1/Videos/Unit 4_Track 9_What's in the box.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/1.png",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/a.png",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/b.png",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P15/Track 4.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/c.png",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/d.png",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P15/two duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/Audios/P15/how many duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page15/E2/6.jpg",
        },
      ],
    ],
  },
};

export default json;
