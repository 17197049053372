import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P40-E1",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   // Column1
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/b.jpg",
      //     audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/Track 8.m4a",
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/c.jpg" },
      // ],
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P40-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/1.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/car.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/bike.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/5.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/ball.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/6.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/7.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/10.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/14.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/16.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/18.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/20.jpg",
        },
      ],
    ],
  },
};

export default json;
