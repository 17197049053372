import MC2 from "../../components/ExcerciseTypes/MultipleChoice/MC2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  9: {
    // Exercise num
    audio: "",
    video: "",
    component: D1,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "9",
        title:
          "Look at the photos. Complete the sentences with school subjects or school words. ",
        color: "#553f9f",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/2.jpg",
          input: true,
          answer: "cience",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/3.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/4.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/6.jpg",
          input: true,
          answer: "otebook",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/7.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/8.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/10.jpg",
          input: true,
          answer: "eography",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/11.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/12.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/14.jpg",
          input: true,
          answer: "aths",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/15.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/16.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/18.jpg",
          input: true,
          answer: "istory",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/19.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/20.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/21.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/22.jpg",
          input: true,
          answer: "CT",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/23.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E9/24.jpg",
        },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  10: {
    // Exercise num
    audio: "",
    video: "",
    component: MC2,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E10/Key/answerKey.png",
    titleQuestion: [
      { num: "10", title: "Choose the correct word.", color: "#3B3074" },
    ],
    titleImage: "http://",
    questionImage: [
      // Row
      [
        // Column
        { url: "http://" },
        { url: "http://", input: true },
        { url: "http://" },
      ],
    ],
    questions: [
      {
        title:
          "Our project is about a special ....... school. It goes along the river.",
        answers: [
          { image: "", isCorrect: false, right: "", text: "private" },
          { image: "", isCorrect: false, right: "", text: "primary" },
          { image: "", isCorrect: true, right: "", text: "boat" },
        ],
        no: 1,
        question: "",
        type: "",
      },
      {
        title: "That’s a ....... school. Students live there.",
        answers: [
          { image: "", isCorrect: true, right: "", text: "boarding" },
          { image: "", isCorrect: false, right: "", text: "private" },
          { image: "", isCorrect: false, right: "", text: "primary" },
        ],
        no: 2,
        question: "",
        type: "",
      },
      {
        title:
          "The school in the photo is a very expensive ....... school for older students.",
        answers: [
          { image: "", isCorrect: false, right: "", text: "boat" },
          { image: "", isCorrect: true, right: "", text: "private" },
          { image: "", isCorrect: false, right: "", text: "primary" },
        ],
        no: 3,
        question: "",
        type: "",
      },
      {
        title: "This is a ....... school. Young children come here every day.",
        answers: [
          { image: "", isCorrect: true, right: "", text: "primary" },
          { image: "", isCorrect: false, right: "", text: "secondary" },
          { image: "", isCorrect: false, right: "", text: "boarding" },
        ],
        no: 4,
        question: "",
        type: "",
      },
      {
        title: `These students here are over eleven years old. It’s a ........ school.`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "primary" },
          { image: "", isCorrect: false, right: "", text: "boat" },
          { image: "", isCorrect: true, right: "", text: "secondary" },
        ],
        no: 5,
        question: "",
        type: "",
      },
    ],
  },
  11: {
    // Exercise num
    audio: "",
    video: "",
    component: T1,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E11/Key/1.png",
    // titleImage: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page59/E5/1.png",
    imgSize: "70%",
    titleQuestion: [
      {
        color: "#3F3478",
        num: "11",
        title:
          "Write affirmative or negative sentences using the present continuous.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> I / study / two languages ✔ #</p>" +
          "<p><span style='font-weight:600'>2.</span> the students / wear / school uniforms ✘ #</p>" +
          "<p><span style='font-weight:600'>3.</span> he / chat / to his best friend ✔ #</p>" +
          "<p><span style='font-weight:600'>4.</span> she / run / for the school bus ✘ #</p>" +
          "<p><span style='font-weight:600'>5.</span> we / have / a break from the lesson ✔ #</p>" +
          "<p><span style='font-weight:600'>6.</span> I / learn / a musical instrument ✘ #</p>",
        answer: [
          "I'm studying two languages",
          "The student aren't wearing school uniforms",
          "He's chatting to his best friend",
          "She isn't running for the school bus",
          "We're having a break from the lesson",
          "I'm not learning a musical instrument",
        ],
        type: "longAnwser",
      },
    ],
  },
  12: {
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/Audio/G6 SB Track 1.49.WAV",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/Key/answerKey.png",
    component: DesignUnderLine,
    totalInput: 5,
    titleQuestion: [
      {
        num: "12",
        title: `Listen to Jack talking about his learning strategies and tick (✔) the correct boxes.`,
        color: "#3B3074",
      },
    ],
    questionImage: [
      // Row

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/03.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/05.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/06.jpg",
          input: 1,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/07.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/08.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/09.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/10.jpg",
          input: 2,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/14.jpg",
          input: 3,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/16.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/18.jpg",
          input: 4,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/20.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/21.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/22.jpg",
          input: 5,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/23.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/24.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/25.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/26.jpg",
          input: 6,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/27.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/28.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/29.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/30.jpg",
          input: 7,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/31.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/32.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/33.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/34.jpg",
          input: 8,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/35.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page60/E12/36.jpg",
        },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};
export default json;
