import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN1-U4-P4-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    // recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E1/1.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P9/it mom.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E1/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P9/it dad.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E1/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E1/5.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P9/it baby.mp3",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page34/E1/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/10.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/14.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/16.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/18.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/20.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P14-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/Audio/tieude.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/Audio/D.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/6.jpg",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/Audio/dog.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/Audio/duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E2/9.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P14-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/1.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/3.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/4.jpg",
          input: 1,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/6.jpg",
          input: 2,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/7.jpg",
          input: 2,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/9.jpg",
          input: 3,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/10.jpg",
          input: 3,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/12.jpg",
          input: 4,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/13.jpg",
          input: 4,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page14/E3/14.jpg",
        },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
