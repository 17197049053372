import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN1-U6-P54-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/3.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/7.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/9.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page54/E1/11.jpg",
        },
      ],
    ],
  },
};

export default json;
