import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P26-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/Audio/audio.e1.mp3",
    video: "",
    component: DesignUnderLine,
    // recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/10.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/14.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/16.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/18.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/20.jpg",
        },
      ],
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "SB1-U3-P26-E2",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/Audio/tieude.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/Audio/horse.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/Audio/insect.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "SB1-U3-P26-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/1.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/3.jpg",
          input: 1,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/4.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/5.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/7.jpg",
          input: 2,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/8.jpg",
          input: 2,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/9.jpg",
          input: 2,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/11.jpg",
          input: 3,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/12.jpg",
          input: 3,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/13.jpg",
          input: 3,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page26/E3/14.jpg",
        },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
