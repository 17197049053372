import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P38-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/b.jpg",
      //   ,
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/a.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/5.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P38-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/b.jpg",
      //   ,
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/a.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E2/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E2/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E2/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E2/5.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P38-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/b.jpg",
      //   ,
      //   },
      //   { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E1/a.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E3/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E3/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E3/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page38/E3/5.jpg",
        },
      ],
    ],
  },
};

export default json;
