import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P2-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track8-car.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row

      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/car.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/5.jpg" }],

      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/7.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/It's a car.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/8.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/9.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/10.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/It's a car.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E1/13.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P2-E2",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track8-bike.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row

      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/bike.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/5.jpg" }],

      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/7.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/It's a bike.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/8.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/9.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/10.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/It's a bike.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E2/12.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P2-E3",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track8-ball.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/1.jpg" }],

      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/ball.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/4.jpg" },
      ],

      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/5.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/6.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/It's a ball.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/7.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/8.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/9.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/It's a ball.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/What is this.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page2/E3/13.jpg",
        },
      ],
    ],
  },
};

export default json;
