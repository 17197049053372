import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN-U6-P65-E1",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E1/1.jpg",
        },
      ],
    ],
  },
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN-U6-P65-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/10.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/14.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/16.jpg",
        },
      ],

      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/18.jpg",
          input: 1,
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/success-Sound.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page40/E2/20.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page44/E1/1.jpg",
        },
      ],
    ],
  },
};

export default json;
