import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U3-P29-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 7.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn1/Videos/Unit 3_Track 7_Look at my face!.mp4",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page29/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page29/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page29/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/Track 7.mp3",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page29/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page29/E1/5.jpg",
        },
      ],
    ],
  },
};

export default json;
