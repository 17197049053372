import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "LQTAMN1-U7-P68-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 17.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn1/Videos/Unit 7_Track 17_I like meat..mp4",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "img/FriendsPlus/Page68/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page68/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page68/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/Track 17.mp3",
        },

        {
          url: "img/FriendsPlus/Page68/E1/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page68/E1/5.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "LQTAMN1-U7-P68-E2",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 17.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn1/Videos/Unit 7_Track 17_I like meat..mp4",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E2/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E2/3.jpg",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E2/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E2/5.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "LQTAMN1-U7-P68-E3",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 17.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn1/Videos/Unit 7_Track 17_I like meat..mp4",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E3/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E3/3.jpg",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E3/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page68/E3/5.jpg",
        },
      ],
    ],
  },
};

export default json;
