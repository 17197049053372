import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN1-U5-P50-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E1/3.jpg",
          audioUrl: "",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E1/4.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E1/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E1/6.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN1-U5-P50-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E2/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E2/3.jpg",
          audioUrl: "",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E2/4.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E2/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E2/6.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN1-U5-P50-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E3/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E3/3.jpg",
          audioUrl: "",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E3/4.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E3/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page50/E3/6.jpg",
        },
      ],
    ],
  },
};

export default json;
