import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U3-P27-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 6.mp3",

    component: UI,
    recorder: true,
    // totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/Track 6.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/7.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/9.jpg",
          audioUrl: "",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page27/E1/10.jpg",
        },
      ],
    ],
  },
};

export default json;
