import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "LQTAMN1-S-P4-E1",
    audio: "https://cdn.sachso.edu.vn/mn1/Audios/Track 1.mp3",

    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/Key/answerKey.png",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn1/Audios/Track 1.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/5.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/7.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P4/Hi.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/8.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/9.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/10.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P4/Hi.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/12.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P4/Hello.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/13.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P4/Hello.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/14.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page4/E1/15.jpg",
        },
      ],
    ],
  },
};

export default json;
