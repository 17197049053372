import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN1-U6-P63-E1",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page63/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<p style="color: #f8931d; font-weight: 600">2. TRACE AND COLOR</p>
`,
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "164px",
              left: "153px",
              width: "279px",
              height: "150px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "410px",
              left: "171px",
              width: "279px",
              height: "150px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "391px",
              left: "485px",
              width: "274px",
              height: "137px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "156px",
              left: "676px",
              width: "274px",
              height: "175px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          },
        ],
        answers: ["0-1", "2-3"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "none",
          // borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "an&nbsponline&nbspencyclopaedia / a&nbsplocal&nbspgovernment&nbspwebsite / a&nbspskateboarding&nbspfan&nbspwebsite.",
          "a&nbspsocial&nbspmedia&nbspupdate / a&nbspskateboarding&nbspequipment&nbspwebsite / a&nbspreview&nbspon&nbspa&nbsppersonal&nbspblog.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <input id=0 type=boxMatch />
        <input id=1 type=boxMatch />
        <input id=2 type=boxMatch />
        <input id=3 type=boxMatch />
       

              <img src='/img/FriendsPlus/Page63/E1/63.jpg' style='height:16cm' />
       
      `,
    },
  },
};

export default json;
