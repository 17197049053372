import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P21-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/Audios/P15/how many duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E1/4.jpg",
        },

        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E1/5.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn1/Audios/P15/one duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E1/6.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P21-E2",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/Audio/audio.e12.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/5.jpg",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page21/E2/7.jpg",
        },
      ],
    ],
  },
};

export default json;
